import type { UserRoleModel } from 'services/models/roleAndPermissions.model';
import { UserRoleColumnKey } from 'services/models/roleAndPermissions.model';

import type { ColumnDefinition } from 'components/BanzaiTable';

export const COLUMN_DEFINITIONS: ReadonlyArray<ColumnDefinition<UserRoleColumnKey, UserRoleColumnKey, UserRoleModel>> =
  [
    {
      key: UserRoleColumnKey.firstName,
      title: 'First Name',
      width: '30%',
      sortField: UserRoleColumnKey.firstName,
      extractCellContents: (userRole: UserRoleModel) => ({
        primary: { text: userRole.firstName },
      }),
    },
    {
      key: UserRoleColumnKey.lastName,
      title: 'Last Name',
      width: '30%',
      sortField: UserRoleColumnKey.lastName,
      extractCellContents: (userRole: UserRoleModel) => ({
        primary: { text: userRole.lastName },
      }),
    },
    {
      key: UserRoleColumnKey.email,
      title: 'Email',
      width: '60%',
      sortField: UserRoleColumnKey.email,
      extractCellContents: (userRole: UserRoleModel) => ({
        primary: { text: userRole.email },
      }),
    },
    {
      key: UserRoleColumnKey.active,
      title: 'Active',
      width: '10%',
      sortField: UserRoleColumnKey.active,
      extractCellContents: (userRole: UserRoleModel) => ({
        primary: { text: userRole.active ? '🟢' : '🔴' },
      }),
    },
    {
      key: UserRoleColumnKey.lastActiveDate,
      title: 'Last Active Date',
      width: '20%',
      sortField: UserRoleColumnKey.lastActiveDate,
      extractCellContents: (userRole: UserRoleModel) => ({
        primary: {
          text: userRole.sources?.[0]?.timestamp
            ? new Date(userRole.sources[0].timestamp).toLocaleString('en-US', {
                dateStyle: 'long',
                timeStyle: 'short',
              })
            : '',
        },
      }),
    },
  ];
