import { Box, CircularProgress } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { useCutOffDay } from 'services/listItems.service';

import { getCurrentPerformanceMonth, getCurrentStatementMonth, getDeadlineLocal } from 'utils/helpers/deadline';

export function Deadline() {
  const { isLoading: isCutoffLoading, cutOffDay } = useCutOffDay();

  if (isCutoffLoading) {
    return (
      <Box flex={1} p={1.5} className='flex justify-center bg-divider'>
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (!cutOffDay) {
    return (
      <Box flex={1} p={1.5} className='flex justify-center bg-divider'>
        <span className='font-normal' data-cy='timeEntryDeadlineBox'>
          Cut-off day is not available.
        </span>
      </Box>
    );
  }

  // return not valid date if the cut off day is not between 2 and 13
  if (Number(cutOffDay) < 2 || Number(cutOffDay) > 13) {
    return (
      <Box flex={1} p={1.5} className='flex justify-center bg-divider' color='red'>
        <span className='font-normal' data-cy='timeEntryDeadlineBox'>
          Cut-off day is not valid, please contact your administrator.
        </span>
      </Box>
    );
  }

  const deadlineDate = getDeadlineLocal(cutOffDay);
  const statementMonth = getCurrentStatementMonth(cutOffDay);
  const performanceMonth = getCurrentPerformanceMonth(cutOffDay);

  return (
    <Box flex={1} p={1.5} className='flex justify-center bg-divider'>
      <InfoIcon className='mr-2.5 h-5 w-5 cursor-pointer text-black' />
      <span className='font-normal' data-cy='timeEntryDeadlineBox'>
        The deadline to submit time entries for the {statementMonth} Payment Statement ({performanceMonth} Performance)
        is{' '}
        <span className='font-bold'>
          {deadlineDate.toLocaleDateString(undefined, { month: 'long', day: 'numeric' })} at{' '}
          {deadlineDate.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}
        </span>
      </span>
    </Box>
  );
}
