import { useAuth } from 'contexts/AuthContext';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { sortByColumn } from 'slices/roleAndPermission';
import { showError, showSuccess } from 'slices/snack';
import { useDispatch, useSelector } from 'stores';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import type { UserRoleModel } from 'services/models/roleAndPermissions.model';
import { COLUMN_DEFINITIONS } from 'services/roleAndPermission/columnDefinitions';
import {
  useUserActiveStatusMutation,
  useUsersWithRolesAndPermissionsList,
} from 'services/roleAndPermission/roleAndPersmssion.service';

import { AuthenticatedLayoutComponent } from 'components/AuthenticatedLayoutComponent';
import { BanzaiTable } from 'components/BanzaiTable';

import { TitlesConstants } from 'utils/constants/title.constant';

import { AddRoleDialog } from './AddRoleDialog';
import { DeleteRoleDialog } from './DeleteRoleDialog';
import { ExpandedRoleAndPermissions } from './ExpandedRoleAndPermissions';

interface SearchBoxProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

// eslint-disable-next-line react/display-name
const SearchBox = React.memo(({ searchQuery, setSearchQuery, inputRef }: SearchBoxProps) => (
  <Box width={1} className='p-1 md:p-1' display='flex' justifyContent='flex-end'>
    <Box mt={2} width='auto'>
      <TextField
        inputRef={inputRef}
        label='Search'
        placeholder='Search users'
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        variant='outlined'
        size='small'
        style={{ width: '200px' }}
      />
    </Box>
  </Box>
));

export function RoleAndPermissionsPage() {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { activeSortColumn } = useSelector(store => ({
    activeSortColumn: store.roleAndPermissions.activeSortColumn,
  }));
  const { isLoading, users } = useUsersWithRolesAndPermissionsList();
  const [searchQuery, setSearchQuery] = useState('');
  const [userToToggle, setUserToToggle] = useState<UserRoleModel | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  useEffect(() => {
    if (searchInputRef.current) {
      const inputElement = searchInputRef.current;
      const savedSelectionStart = inputElement.selectionStart;
      const savedSelectionEnd = inputElement.selectionEnd;
      inputElement.focus();
      inputElement.setSelectionRange(savedSelectionStart, savedSelectionEnd);
    }
  }, [searchQuery]);

  const filteredUsers = useMemo(() => {
    if (!searchQuery) {
      return users;
    }
    return users?.filter(user => {
      const lowerQuery = searchQuery.toLowerCase();
      return `${user.firstName} ${user.lastName}`.toLowerCase().includes(lowerQuery);
    });
  }, [users, searchQuery]);

  const onSuccess = useCallback(() => {
    const message = `Status Successfully updated`;
    dispatch(showSuccess({ message }));
  }, [dispatch]);

  const onError = useCallback(() => {
    const message = `An error occurred while updating the status`;
    dispatch(showError({ message }));
  }, [dispatch]);

  const { userActiveStatus } = useUserActiveStatusMutation({ onSuccess, onError });
  const toggleUserActive = useCallback(() => {
    if (userToToggle) {
      // This is where you would dispatch an API call or Redux action to toggle the user's active state.
      userActiveStatus({ userId: userToToggle.id, active: !userToToggle.active });
      setUserToToggle(null);
    }
  }, [userActiveStatus, userToToggle]);

  const getUserActions = useCallback(
    (rowUser: UserRoleModel) => {
      if (user?.id === rowUser.id) {
        return [];
      }
      return [
        {
          id: rowUser.active ? 'Deactivate' : 'Activate',
          // eslint-disable-next-line react/no-unstable-nested-components
          Icon: () => (
            <Button
              variant='contained'
              size='small'
              style={{
                backgroundColor: rowUser.active ? 'red' : 'green',
                color: 'white',
                textTransform: 'none',
              }}
              onClick={e => {
                e.stopPropagation();
                setUserToToggle(rowUser);
              }}>
              {rowUser.active ? 'Deactivate' : 'Activate'}
            </Button>
          ),
          colorClassName: rowUser.active ? 'text-error' : 'text-success',
          handler: () => setUserToToggle(rowUser),
        },
      ];
    },
    [user],
  );

  const AboveTableComponent = useCallback(
    () => <SearchBox searchQuery={searchQuery} setSearchQuery={handleSearchChange} inputRef={searchInputRef} />,
    [searchQuery, handleSearchChange],
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const ToggleUserActiveDialog = () => {
    if (!userToToggle) return null;

    return (
      <Dialog open={!!userToToggle} onClose={() => setUserToToggle(null)}>
        <DialogTitle>{userToToggle.active ? 'Deactivate' : 'Activate'} User</DialogTitle>
        <DialogContent>
          Are you sure you want to {userToToggle.active ? 'deactivate' : 'activate'} {userToToggle.firstName}{' '}
          {userToToggle.lastName}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserToToggle(null)}>Cancel</Button>
          <Button onClick={toggleUserActive} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <AuthenticatedLayoutComponent title={TitlesConstants.roleAndPermissions}>
      <BanzaiTable
        tableId='roleAndPermissions'
        pageHeading='Role and Permissions'
        tableHeading='Users'
        isLoading={isLoading}
        records={filteredUsers}
        columns={COLUMN_DEFINITIONS}
        sorting={{
          activeSortColumn,
          sortRecords: (key, defaultSortDirection) => {
            dispatch(sortByColumn({ key, defaultSortDirection }));
          },
        }}
        getRecordActions={getUserActions}
        ExpandedRecordComponent={ExpandedRoleAndPermissions}
        emptyTableText='No users found'
        pluralRecordsText='users'
        AboveTableComponent={AboveTableComponent}
      />
      <ToggleUserActiveDialog />
      <AddRoleDialog />
      <DeleteRoleDialog />
    </AuthenticatedLayoutComponent>
  );
}

export default RoleAndPermissionsPage;
