import { useMemo } from 'react';
import { useQuery } from 'react-query';

import API from 'utils/helpers/axiosInstance';

import type { UserModel } from './models/auth.model';
import type { ContractRoleModel, MeetingCategoryModel, PracticeModel } from './models/meetingTimeEntry.model';
import type { ProgramModel } from './models/program.model';

const DEFAULT_OPTIONS = { staleTime: 1800000 };

async function listContractRolesApi() {
  const result = await API.get<ReadonlyArray<ContractRoleModel>>('list/contractRoles');
  return result.data;
}

async function listMeetingCategoriesApi() {
  const result = await API.get<ReadonlyArray<MeetingCategoryModel>>('list/meetingCategories');
  return result.data;
}

async function listPracticesApi() {
  const result = await API.get<ReadonlyArray<PracticeModel>>('list/practices');
  return result.data;
}

async function listProgramsApi() {
  const result = await API.get<ReadonlyArray<ProgramModel>>('list/programs');
  return result.data;
}

async function listUsersApi() {
  const result = await API.get<ReadonlyArray<UserModel>>('list/users');
  return result.data;
}

async function getCutOffDayApi() {
  const result = await API.get<string>('cutOffDay');
  return result.data;
}

export function useContractRolesList() {
  const {
    isLoading,
    isRefetching,
    data: contractRoles,
  } = useQuery('list/contractRoles', listContractRolesApi, DEFAULT_OPTIONS);
  return { isLoading: isLoading || isRefetching, contractRoles };
}

export function useMeetingCategoriesList() {
  const { isLoading, isRefetching, data } = useQuery(
    'list/meetingCategories',
    listMeetingCategoriesApi,
    DEFAULT_OPTIONS,
  );

  const meetingCategories = useMemo(
    () =>
      data && [
        ...data.filter(category => category.id !== 'OTHER'),
        ...data.filter(category => category.id === 'OTHER'),
      ],
    [data],
  );
  return { isLoading: isLoading || isRefetching, meetingCategories };
}

export function usePracticesList() {
  const { isLoading, isRefetching, data: practices } = useQuery('list/practices', listPracticesApi, DEFAULT_OPTIONS);
  return { isLoading: isLoading || isRefetching, practices };
}

export function useProgramsList() {
  const { isLoading, isRefetching, data: programs } = useQuery('list/programs', listProgramsApi, DEFAULT_OPTIONS);
  return { isLoading: isLoading || isRefetching, programs };
}

export function useUsersList() {
  const { isLoading, isRefetching, data: users } = useQuery('list/users', listUsersApi, DEFAULT_OPTIONS);
  return { isLoading: isLoading || isRefetching, users };
}

export function useCutOffDay() {
  const { isLoading, isRefetching, data: cutOffDay } = useQuery('cutOffDay', getCutOffDayApi, DEFAULT_OPTIONS);
  return { isLoading: isLoading || isRefetching, cutOffDay };
}
