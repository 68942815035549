import { useAuth } from 'contexts/AuthContext';
import moment from 'moment';
import { openSelectAttendeeDialog, openSelectPracticeDialog } from 'slices/practiceCollaboration';
import { useDispatch } from 'stores';

import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import InfoIcon from '@material-ui/icons/Info';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { useCutOffDay } from 'services/listItems.service';
import type {
  MeetingCategoryModel,
  PracticeModel,
  UpdateHourlyConsultModel,
} from 'services/models/meetingTimeEntry.model';

import { useStyles } from 'pages/PracticeCollaborationTrackerPage/PracticeCollaborationTrackerPage.styles';

import { Button } from 'components/Button';

import { determineStatementMonth } from 'utils/helpers/deadline';
import { getMinDate } from 'utils/helpers/displayDate';
import { getShortCurrentTimezoneName } from 'utils/helpers/formatTime';
import type { IErrorState } from 'utils/validation/error-state';

import { Deadline } from '../Deadline';

interface OldMeetingTimeEntryDialogProps extends IErrorState {
  isOpen: boolean;
  isLoading: boolean;
  params: UpdateHourlyConsultModel;
  isCheckedForCreating: boolean;
  meetingCategories?: ReadonlyArray<MeetingCategoryModel>;
  practices?: ReadonlyArray<PracticeModel>;
  handleClose: () => void;
  handleChanges: (field: string, value: any) => void;
  handleCreate: () => void;
  handleRemoveAttendee: (id: string) => void;
  handleDurationChanges: (id: string, value: any) => void;
  handleToggleConfirm: (id: string) => void;
}

export function OldMeetingTimeEntryDialog(props: OldMeetingTimeEntryDialogProps) {
  const { isOpenCreateDialog } = useAuth();
  const dispatch = useDispatch();
  const {
    isOpen,
    isLoading,
    params,
    errors,
    isCheckedForCreating,
    meetingCategories,
    practices,
    handleClose,
    handleChanges,
    handleCreate,
    handleRemoveAttendee,
    handleDurationChanges,
    handleToggleConfirm,
  } = props;

  const { isLoading: isCutoffLoading, cutOffDay } = useCutOffDay();

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isOtherCategory = params.meetingCategoryId === 'OTHER';

  if (!cutOffDay) {
    return (
      <Box flex={1} p={1.5} className='flex justify-center bg-divider'>
        <span className='font-normal' data-cy='timeEntryDeadlineBox'>
          Cut-off day is not available.
        </span>
      </Box>
    );
  }

  return (
    <Dialog
      maxWidth='xl'
      fullWidth
      fullScreen={isOpenCreateDialog}
      open={isOpen && !isCutoffLoading}
      onClose={(e, reason: string) => reason !== 'backdropClick' && handleClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='overflow-x-hidden px-1 py-3 sm:p-8'>
        <Box className='xl:flex' justifyContent='space-between' alignItems='center'>
          <Box pr={3}>
            <span className='text-3xl font-semibold text-black'>
              {params.id ? 'Update Time Entry' : 'Create Time Entry'}
            </span>
          </Box>
          <Deadline />
        </Box>
        <Box className='mt-4 sm:mt-10'>
          <Grid container item direction='row'>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <Box className='text-2xl font-semibold text-black'>Event</Box>
              <Box mt={3}>
                <Box className='text-base font-semibold text-black1' mb={0.5}>
                  Meeting Category
                </Box>
                <FormControl
                  variant='outlined'
                  size='small'
                  fullWidth
                  classes={{ root: classes.root }}
                  error={!!errors.meetingCategoryId}>
                  <Select
                    classes={{ root: classes.root }}
                    value={params.meetingCategoryId}
                    renderValue={() => {
                      if (!params.meetingCategoryId) {
                        return <Box className='text-base font-normal text-black2'>– Select Meeting Category –</Box>;
                      }

                      return (
                        <Box className='text-base font-normal text-black2'>
                          {meetingCategories?.find(c => c.id === params.meetingCategoryId)?.name}
                        </Box>
                      );
                    }}
                    displayEmpty
                    disabled={isLoading}
                    onChange={event => {
                      handleChanges('meetingCategoryId', event.target.value);
                    }}>
                    {meetingCategories?.map(item => (
                      <MenuItem key={`meeting-category-id-${item.id}`} value={item.id}>
                        <Box display='flex' alignItems='center' justifyContent='space-between' width={1}>
                          <Box>{item.name}</Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{errors.meetingCategoryId}</FormHelperText>
                </FormControl>
              </Box>
              {isOtherCategory && (
                <Box mt={1}>
                  <Box className='text-base font-semibold text-black1' mb={0.5}>
                    Please specify:
                  </Box>
                  <FormControl
                    variant='outlined'
                    size='small'
                    fullWidth
                    classes={{ root: classes.root }}
                    error={!!errors.otherCategory}>
                    <TextField
                      id='otherCategory'
                      fullWidth
                      required
                      size='small'
                      placeholder=''
                      InputProps={{ className: 'input' }}
                      variant='outlined'
                      disabled={isLoading}
                      value={params.otherCategory}
                      onChange={event => {
                        handleChanges(event.target.id, event.target.value);
                      }}
                    />
                    <FormHelperText error>{errors.otherCategory}</FormHelperText>
                  </FormControl>
                </Box>
              )}
              <Box mt={4}>
                <Grid container item direction='row' spacing={isSmallScreen ? 2 : 6}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box className='text-base font-semibold text-black1' mb={0.5}>
                      Meeting Date
                    </Box>
                    <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                      <KeyboardDatePicker
                        disableToolbar
                        disableFuture
                        minDate={moment(getMinDate())}
                        variant='inline'
                        fullWidth
                        size='small'
                        inputVariant='outlined'
                        format='MM/DD/yyyy'
                        placeholder='MM/DD/YYYY'
                        id='meetingDate'
                        error={!!errors.meetingDate}
                        helperText={null}
                        value={params.meetingDate}
                        disabled={isLoading}
                        onChange={momentDate => {
                          handleChanges('meetingDate', momentDate === null ? '' : momentDate.format('YYYY-MM-DD'));
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                          edge: 'end',
                        }}
                        keyboardIcon={<CalendarTodayIcon />}
                      />
                      <FormHelperText error>{errors.meetingDate}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box className='flex items-center text-base font-semibold text-black1' mb={0.5}>
                      Monthly Statement
                      <Tooltip
                        title={
                          <Box px={2}>
                            This is the Monthly Practice Payment Statement that will include this time entry.
                          </Box>
                        }
                        placement='top'
                        arrow
                        classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}>
                        <InfoIcon className='ml-3 cursor-pointer text-black' />
                      </Tooltip>
                    </Box>
                    <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                      <TextField
                        id='payment-round'
                        fullWidth
                        disabled
                        size='small'
                        placeholder=''
                        InputProps={{ className: 'input' }}
                        variant='outlined'
                        className='bg-disabled'
                        value={determineStatementMonth(new Date(params.meetingDate), cutOffDay)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={5}>
                <Box className='text-base font-semibold text-black1' mb={0.5}>
                  Scheduled Meeting Time <span className='font-normal'>(in {getShortCurrentTimezoneName()})</span>
                </Box>
                <Box className='flex'>
                  <Box flex={1}>
                    <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                      <TextField
                        id='meeting-start-time'
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='time'
                        placeholder='- Select Start Time -'
                        value={params.startTime}
                        error={!!errors.startTime}
                        disabled={isLoading}
                        onChange={event => {
                          handleChanges('startTime', event.target.value);
                        }}
                        inputProps={{
                          className: 'input',
                          step: 300,
                        }}
                      />
                      <FormHelperText error>{errors.startTime}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box px={2} pt={2.5}>
                    to
                  </Box>
                  <Box flex={1}>
                    <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                      <TextField
                        id='meeting-end-time'
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='time'
                        placeholder='- Select End Time -'
                        value={params.endTime}
                        error={!!errors.endTime}
                        disabled={isLoading}
                        onChange={event => {
                          handleChanges('endTime', event.target.value);
                        }}
                        inputProps={{
                          className: 'input',
                          step: 300,
                        }}
                      />
                      <FormHelperText error>{errors.endTime}</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box className='mt-4 sm:mt-20'>
                <Box className='text-xl text-black'>
                  <span className='font-semibold'>
                    Internal Notes <span className='font-normal'>(optional)</span>
                  </span>
                </Box>
                <Box mt={1.5}>
                  <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                    <TextField
                      id='note'
                      fullWidth
                      required
                      multiline
                      minRows={5}
                      size='small'
                      placeholder='Add your notes'
                      InputProps={{ className: 'input' }}
                      variant='outlined'
                      disabled={isLoading}
                      value={params.note}
                      onChange={event => {
                        handleChanges(event.target.id, event.target.value);
                      }}
                      error={!!errors.note}
                    />
                    <FormHelperText error>{errors.note}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <Box className='flex h-full'>
                <Divider orientation='vertical' className='mx-8 hidden border-divider xl:block' />
                <Box width={1} className='flex flex-col pt-4 xl:pt-0'>
                  <Box width={1} flex={1}>
                    <Box display='flex' justifyContent='space-between'>
                      <Box className='text-2xl font-semibold text-black'>Participants</Box>
                      <Box mt={1}>
                        <Button
                          id='select-practice-button'
                          color='secondary'
                          size='medium'
                          borderRadius={50}
                          disabled={isLoading}
                          onClick={() => {
                            dispatch(openSelectPracticeDialog());
                          }}>
                          Select Practice
                        </Button>
                      </Box>
                    </Box>
                    <Box mt={1.5} mb={4}>
                      <Box display='flex' py={1.5} className='rounded-t-xl bg-divider'>
                        <Box px={2} width={0.7} className='font-semibold text-black'>
                          Practice (Legal Business Name)
                        </Box>
                        <Box px={2} width={0.3} className='font-semibold text-black'>
                          TIN
                        </Box>
                      </Box>
                      {params.practiceId ? (
                        <Box py={3} className='flex items-center'>
                          <Box px={2} width={0.7} className='font-normal'>
                            <Box className='break-all'>
                              {practices?.find(x => x.id === params.practiceId)?.legalBusinessName}
                            </Box>
                          </Box>
                          <Box px={2} width={0.25} className='break-words font-normal'>
                            {params.practiceTin}
                          </Box>
                          {!params.id && (
                            <Box width={0.05} className='flex justify-end'>
                              <TrashIcon
                                className='cursor-pointer text-error'
                                onClick={() => {
                                  handleToggleConfirm('');
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box className='rounded-b-xl bg-gray4 p-4 text-center italic text-black4'>
                          No Practice Selected
                        </Box>
                      )}
                    </Box>
                    <Box display='flex' justifyContent='flex-end'>
                      <Button
                        id='select-practice-button'
                        color='secondary'
                        size='medium'
                        borderRadius={50}
                        disabled={!params.practiceId || isLoading}
                        onClick={() => {
                          dispatch(openSelectAttendeeDialog());
                        }}>
                        + Add Attendees
                      </Button>
                    </Box>
                    <Box mt={1.5} pb={5}>
                      <Box display='flex' py={1.5} className='rounded-t-xl bg-divider'>
                        <Box px={2} width={0.25} className='font-semibold text-black'>
                          Attendee
                        </Box>
                        <Box px={2} width={0.2} className='font-semibold text-black'>
                          Job Title
                        </Box>
                        <Box px={2} width={0.2} className='font-semibold text-black'>
                          Contract Role
                        </Box>
                        <Box px={2} width={0.35} className='font-semibold text-black'>
                          Attendance Duration (minutes)
                        </Box>
                      </Box>
                      {params.attendees.length > 0 ? (
                        params.attendees.map(attendee => (
                          <Box key={attendee.id} display='flex' py={1.5}>
                            <Box px={2} width={0.25} className='font-normal sm:pt-3'>
                              <Box>{attendee?.displayName}</Box>
                            </Box>
                            <Box px={2} width={0.2} className='font-normal sm:pt-3'>
                              {attendee.jobTitle}
                            </Box>
                            <Box px={2} width={0.2} className='font-normal sm:pt-3'>
                              {attendee.contractRole.name}
                            </Box>
                            <Box px={2} width={0.3} className='font-normal'>
                              <FormControl variant='outlined' size='small' fullWidth classes={{ root: classes.root }}>
                                <TextField
                                  id={`duration-${attendee.id}`}
                                  fullWidth
                                  required
                                  size='small'
                                  type='text'
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                    className: 'input',
                                  }}
                                  className={classes.input}
                                  variant='outlined'
                                  disabled={isLoading}
                                  value={attendee.duration}
                                  error={!!errors[`duration-${attendee.id}`]}
                                  onChange={event => {
                                    handleDurationChanges(attendee.id, event.target.value);
                                  }}
                                />
                                <FormHelperText error>{errors[`duration-${attendee.id}`]}</FormHelperText>
                              </FormControl>
                            </Box>
                            <Box width={0.05} pt={1.5} className='flex justify-end'>
                              <TrashIcon
                                className='cursor-pointer text-error'
                                onClick={() => {
                                  handleRemoveAttendee(attendee.id);
                                }}
                              />
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box className='rounded-b-xl bg-gray4 p-4 text-center italic text-black4'>
                          No Attendees Selected
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box display='flex' padding={2} justifyContent='flex-end' alignItems='center'>
                    {!isOpenCreateDialog && (
                      <Box mx={2}>
                        <Button
                          id='dialog-close-button'
                          onClick={handleClose}
                          color='tertiary'
                          size='medium'
                          borderRadius={50}
                          disabled={isLoading}>
                          Cancel
                        </Button>
                      </Box>
                    )}
                    <Box>
                      <Button
                        id='add-consult-button'
                        size='medium'
                        disabled={!isCheckedForCreating}
                        onClick={handleCreate}
                        borderRadius={50}
                        loading={isLoading}>
                        {params.id ? 'Update Time Entry' : 'Create Time Entry'}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
