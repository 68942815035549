import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { AppRoleInput, UserRoleModel } from 'services/models/roleAndPermissions.model';

import API from 'utils/helpers/axiosInstance';

async function getUsersWithRolesAndPermissions(): Promise<ReadonlyArray<UserRoleModel>> {
  const result = await API.get<ReadonlyArray<UserRoleModel>>('users');
  return result.data;
}

async function deleteRoleApi(input: AppRoleInput): Promise<any> {
  const { ...data } = input;
  const result = await API.delete<any>(`users/role`, { data });
  return result.data;
}

async function addRoleApi(input: AppRoleInput): Promise<any> {
  const result = await API.post<any>(`users/role`, { app: input.app, roleName: input.roleName, userId: input.userId });
  return result.data;
}

async function userActiveStatusApi({ userId, active }: { userId: string; active: boolean }): Promise<any> {
  const result = await API.put<any>(`users/active`, { userId, active });
  return result.data;
}

export function useUsersWithRolesAndPermissionsList() {
  const { isLoading, data: users } = useQuery('users', getUsersWithRolesAndPermissions);
  return { isLoading, users };
}

export function useDeleteRoleMutation({ onSuccess, onError }: any) {
  const queryClient = useQueryClient();
  const { isLoading: isDeleteRoleInProgress, mutate } = useMutation(deleteRoleApi);
  const deleteRole = useCallback(
    (input: AppRoleInput) => {
      mutate(input, {
        onSuccess: input => {
          queryClient.invalidateQueries('currentUser');
          queryClient.invalidateQueries('users');
          onSuccess(input);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );
  return { isDeleteRoleInProgress, deleteRole };
}

export function useAddRoleMutation({ onSuccess, onError }: any) {
  const queryClient = useQueryClient();
  const { isLoading: isAddRoleInProgress, mutate } = useMutation(addRoleApi);
  const addRole = useCallback(
    (input: AppRoleInput) => {
      mutate(input, {
        onSuccess: input => {
          queryClient.invalidateQueries('currentUser');
          queryClient.invalidateQueries('users');
          onSuccess(input);
        },
        onError: (error: unknown) => {
          Sentry.captureException(error);
          onError(error);
        },
      });
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { isAddRoleInProgress, addRole };
}

export function useUserActiveStatusMutation({ onSuccess, onError }: any) {
  const queryClient = useQueryClient();
  const { isLoading: isUserActiveStatusInProgress, mutate } = useMutation(userActiveStatusApi);
  const userActiveStatus = useCallback(
    ({ userId, active }: { userId: string; active: boolean }) => {
      mutate(
        { userId, active },
        {
          onSuccess: input => {
            queryClient.invalidateQueries('currentUser');
            queryClient.invalidateQueries('users');
            onSuccess(input);
          },
          onError: (error: unknown) => {
            Sentry.captureException(error);
            onError(error);
          },
        },
      );
    },
    [mutate, queryClient, onSuccess, onError],
  );

  return { isUserActiveStatusInProgress, userActiveStatus };
}
